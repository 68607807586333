'use client';
import React from 'react';
import { MdPlace } from "react-icons/md";
import {
  Box,
  Flex,
  Text,
  Image,
  List,
  ListItem,
  ListIcon,
  VStack,
  HStack,
  Link,
  Spacer,
  Stack,
  Button,
  useMediaQuery
} from '@chakra-ui/react';
import {
  FaFacebook,
  FaInstagram,
  FaLinkedin,
  FaWhatsapp,
  FaYoutube,
} from 'react-icons/fa';

const Footer = () => {

  const [isLargerThan680] = useMediaQuery('(min-width: 680px)');

  const iwinPhone=5491122604444;
  const message="Hola, me contacto desde Interwin web...";
  const fullMessage = `${message} `;
  const whatsappUrl = `https://wa.me/${iwinPhone}?text=${encodeURIComponent(fullMessage)}`;

  return (
    <Box zIndex={10} bg={'#CFCFCF'}  w={'100%'} pt={'30px'}  mt={'10px'} bottom={'0'} id='footer'  
    h={{sm:'auto',md:'auto', lg:'auto', xl:'auto'}} >
      <Stack
      direction={{base:'column',sm:'column',md:'column', lg:'row', xl:'row'}}
       
       spacing={{base:'30px',sm:'30px',md:'30px', lg:'', xl:''}}
       textAlign={'center'}
      justifyContent={'space-around'} pb='10px' alignItems={'center'} padding={{sm:'10px'}}>
      <Box display={'block'} maxW="max-content">
        <VStack align="center">
          <Image
            src="/img/iwin dark.png"
            width={'220px'}
            alt="Logo"
           ></Image>

<HStack spacing={4} color={'rgb(28, 28, 28)'}>
    <Link  marginLeft={'0px !important'} lineHeight={'none'} _hover={{color:'#e1a360'}} href='https://es-la.facebook.com/interwin.itw/' target='_blank' rel='noopener noreferrer'>
    <Button p='0px' variant="nav"> <FaFacebook size={16}/></Button>
        </Link>
        <Link  marginLeft={'0px !important'}  margin={0} lineHeight={'none'} _hover={{color:'#e1a360'}} href='https://www.instagram.com/interwin.itw/?hl=es' target='_blank' rel='noopener noreferrer'>
          <Button p='0' variant="nav"><FaInstagram size={16}/></Button>
        </Link>
        <Link  marginLeft={'0px !important'}  margin={0} lineHeight={'none'} _hover={{color:'#e1a360'}} href='https://ar.linkedin.com/company/interwin-marketing-inmobiliario' target='_blank' rel='noopener noreferrer' >
          <Button p='0' variant="nav"><FaLinkedin size={16}/></Button>
        </Link>
        <Link  marginLeft={'0px !important'} margin={0} lineHeight={'none'}  href={whatsappUrl} target='_blank' _hover={{color:'#e1a360'}} >
          <Button p='0' variant="nav">< FaWhatsapp size={16}/></Button>
        </Link>
        <Link marginLeft={'0px !important'} margin={0} lineHeight={'none'} _hover={{color:'#e1a360'}}  href='https://www.youtube.com/interwinargentina' >
          <Button p='0' variant="nav"><FaYoutube size={16}/></Button>
        </Link>
    </HStack>


          
          <Text fontFamily="Heebo, sans-serif"  fontSize='12px'color={'rgb(28, 28, 28)'}>
            ITW MARKETING INMOBILIARIO S.A. <br />
            CUIT 30-71138394-4 <br/>
            Miguel Eduardo Ludmer, Matrícula Nro. 945 CUCICBA
          </Text>
        </VStack>
      </Box>
      <Box display={'flex'}  maxW="max-content" margin={'50px auto'}>
        <VStack align="center">
          <Text fontWeight={400} fontSize={'1.1rem'}
                        color={'rgb(28, 28, 28)'}
                        alignSelf={'center'}
                        display={'block'}
                 
                        fontFamily="Heebo, sans-serif">SUCURSALES</Text>
          <Stack direction={{base:'column',sm:'column',md:'row',lg:'row',xl:'row'}} spacing={5}  color={'rgb(35, 35, 35)'} >
            <Box>
      
              
              <Text fontFamily="Heebo, sans-serif " fontSize={'0.9rem'}>
                  <strong style={{ display: 'inline-flex', alignItems: 'flex-start' }}><MdPlace size={16} /> LAS CAÑITAS</strong>  <br />
                  Bvd Chenaut 1956, Buenos Aires. <br />
                +54(11)5275-4300
              </Text>
     
            </Box>
            <Spacer/>
            <Box>
              <Text fontFamily="Heebo, sans-serif" fontSize={'0.9rem'}>
                <strong  style={{ display: 'inline-flex', alignItems: 'flex-start' }}> <MdPlace size={16} /> OFICINA CORPORATIVA</strong> <br />
                L.M. Campos 559, piso 10, of 01 <br />
                +54(11)5275-4200
              </Text>
            </Box>
            <Spacer/>
            <Box>
              <Text fontFamily="Heebo, sans-serif" fontSize={'0.9rem'}>
                <strong  style={{ display: 'inline-flex', alignItems: 'flex-start' }}> <MdPlace size={16} /> PALERMO NUEVO</strong> <br />
               Demaria 4611, Buenos Aires <br />
                +54(11)5275-4275
              </Text>
            </Box>
            <Spacer/>
            <Box>
              <Text fontFamily="Heebo, sans-serif" fontSize={'0.9rem'}>
                <strong  style={{ display: 'inline-flex', alignItems: 'flex-start' }}> <MdPlace size={16} /> NORDELTA</strong> <br />
                Calle del Caminante 30:<br/>
                Puerta Norte II. Piso 3 Of.332<br/>
                 Tigre, Buenos Aires, Argentina.<br/> 
                +54(11)5275-4275
              </Text>
            </Box>
          </Stack>
        </VStack>
      </Box>

      <Box  maxW="max-content">
        <Text fontSize='10px' fontWeight={'bold'}  color='black'fontFamily="Heebo, sans-serif">Formulario 960</Text>
        <Link href='http://qr.afip.gob.ar/?qr=2QP7sGkwa8_rhPjhXDgC7A,' target='_blank' rel='noopener noreferrer'
      as='a'>
      <Image
            src="/img/datafiscal.jpg"
            width={'65px'}
            alt="afip data"
            pl="5px"
            mb={'20px'}></Image>
            </Link>
      </Box>
      </Stack>


      <Flex h="40px" alignItems={'center'} justifyContent={'center'} w={'100vw'} 
       boxShadow="  2px 2px 12px rgba(128, 128, 128, 0.4)">
        <Text mt={1} textColor={'#858585'}>
          Made with <span style={{ color: 'grey' }}>♥</span> by  <a
          href="https://www.venturing.com.ar"
          target="_blank"
          style={{ textDecoration: 'none', color: '#858585', marginLeft: '4px', fontWeight: '600' }}
        >
          Venturing
        </a>
        </Text>
      </Flex>
    </Box>
  );
};

export default Footer;
